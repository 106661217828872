import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "addressEditorModal",
    title: "查看关联订单",
    width: "800px",
    "confirm-loading": _ctx.submiting,
    footer: null,
    onCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        columns: _ctx.tableColumns,
        "row-key": "orderNo",
        "data-source": _ctx.list,
        style: {"width":"100%"}
      }, null, 8, ["columns", "data-source"])
    ]),
    _: 1
  }, 8, ["visible", "confirm-loading", "onCancel"]))
}