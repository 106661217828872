import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a7f81ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fapiaoList__content" }
const _hoisted_2 = { class: "routeTab" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_fillInvoice = _resolveComponent("fillInvoice")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer fapiaoList" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: { name: 'fapiao.issue_houpiao.list' } }, {
            default: _withCtx(() => [
              _createTextVNode("加油单开发票")
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, { to: { name: 'fapiao.issue_discount.list' } }, {
            default: _withCtx(() => [
              _createTextVNode("折扣卡开发票")
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, { to: { name: 'fapiao.houpiao.list' } }, {
            default: _withCtx(() => [
              _createTextVNode("发票记录")
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, { to: { name: 'fapiao.prepaidCard_invoice.list' } }, {
            default: _withCtx(() => [
              _createTextVNode("预付卡开发票")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createVNode(_component_a_form, {
          class: "fl-clean filterForm fapiaoList__filterForm",
          model: _ctx.filterParams,
          layout: 'inline'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "订单日期" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_range_picker, {
                  value: _ctx.filterParams.applyDate,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.applyDate) = $event)),
                  format: "YYYY-MM-DD",
                  placeholder: ['起始时间', '结束时间']
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "公司" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_enterprise_input, {
                  value: _ctx.filterParams.enterpriseId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterParams.enterpriseId) = $event)),
                  style: {"width":"200px"},
                  "disable-in-open": "",
                  "with-default-all": ""
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "批次ID" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.filterParams.batchId,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterParams.batchId) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  ghost: "",
                  onClick: _ctx.onRefreshTable
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode(_component_app_table, {
          ref: "tableRef",
          size: "default",
          "row-key": "id",
          "show-pagination": true,
          columns: _ctx.tableColumns,
          data: _ctx.loadTableData,
          scroll: { x: true }
        }, {
          action: _withCtx(({ record }) => [
            (record.invoiceStatus !== 'invoiced')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.viewDetail(record)), ["prevent"])
                }, "开发票", 8, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["columns", "data"]),
        (_ctx.showConfirmModal)
          ? (_openBlock(), _createBlock(_component_fillInvoice, {
              key: 0,
              "invoice-record": _ctx.invoiceRecord,
              onClose: _ctx.closeConfirmModal
            }, null, 8, ["invoice-record", "onClose"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}