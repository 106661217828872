
import { defineComponent, computed, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import moment, { Moment } from 'moment';
import { dispatchDownload, noEmptyProp, not, formatWithIntl } from '@/utils/illuminate';

import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';
import { formatDate } from '@/core/filters';

import { invoiceTypeMap } from '@/views/fapiao/parts/utils';
import RelOrderModel from './parts/RelOrderModel.vue';
import DetailDrawer from './parts/HoupiaoDetailDrawer.vue';

const invoiceStatusMap: Record<string, string> = {
  0: '待开票',
  1: '开票成功',
  2: '开票失败',
  3: '待冲红',
  4: '冲红成功',
  9: '申请失败',
};

const sendStatusMap: Record<string, string> = {
  0: '未邮寄',
  1: '已邮寄',
  2: '已自取',
};

export default defineComponent({
  name: 'FapiaoListView',
  components: {
    RelOrderModel,
    DetailDrawer,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const route = useRoute();
    const isBefore = computed(() => /xianpiao\.list$/.test(route.name as string));
    const currentErrorMsg = ref('');
    const errorModalVisible = computed({
      get() {
        return !!currentErrorMsg.value;
      },
      set(val) {
        currentErrorMsg.value = val ? currentErrorMsg.value : '';
      },
    });

    const showError = (error: string) => {
      currentErrorMsg.value = error;
    };

    const filterParams = reactive<{
      timeRange: Moment[];
      invoiceType: string;
      invoiceStatus: string;
      enterpriseId: number | string;
    }>({
      timeRange: [],
      invoiceType: '',
      invoiceStatus: '',
      enterpriseId: '',
    });

    const tableColumns: TableColumn[] = [
      { title: '发票抬头', dataIndex: 'invoiceTitle', align: 'center', width: '150px' },
      { title: '申请日期', dataIndex: 'createTime', customRender: ({ text }) => formatDate(text), align: 'center', width: '150px' },
      { title: '发票编号', dataIndex: 'invoiceNo', customRender: ({ text }) => text || '---', align: 'center', width: '150px' },
      { title: '申请人手机号', dataIndex: 'phone', customRender: ({ text }) => text || '---', align: 'center', width: '150px' },
      { title: '申请开票金额', dataIndex: 'applyInvoiceTotalFee', customRender: ({ text }) => (text ? formatWithIntl(text) : '---'), align: 'center', width: '120px' },
      { title: '发票金额', dataIndex: 'totalFee', customRender: ({ text }) => (text ? formatWithIntl(text) : '---'), align: 'center', width: '120px' },
      { title: '发票类型', dataIndex: 'invoiceType', customRender: ({ text }) => invoiceTypeMap[text], align: 'center', width: '120px' },
      { title: '发票状态', dataIndex: 'invoiceStatus', customRender: ({ text }) => invoiceStatusMap[text], align: 'center', width: '120px' },
      { title: '邮寄状态', dataIndex: 'mailingStatus', customRender: ({ text }) => (sendStatusMap[text] || '---'), align: 'center', width: '120px' },
      { title: '发票号', dataIndex: 'oilInvoiceNo', customRender: ({ text }) => (text || '---'), align: 'center', width: '150px' },
      { title: '关联订单', dataIndex: 'rel', slots: { customRender: 'view-order' }, align: 'center', width: '120px' },
      { title: '操作', dataIndex: 'action', width: '200px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const tableRef = ref<any>(null);
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      const path = isBefore.value ? '/invoice/find-invoice-list-before' : '/invoice/find-invoice-list-after';

      const params: Record<string, any> = {
        ...parameter, ...not(noEmptyProp(filterParams), ['timeRange']),
      };

      if (filterParams.timeRange.length) {
        params.startTime = moment(filterParams.timeRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
        params.endTime = moment(filterParams.timeRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
      }

      request.get(createApiUrl(`/newlinkSass${path}`), { params, noEnterpriseId: true })
        .then((res: any) => {
          resolve({
            ...res.data,
            list: res.data.list.map((item: any) => ({
              ...item, id: `${item.invoiceNo}${item.id}`,
            })),
          });
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const relOrderModelRef = ref<any>(null as any);
    const onViewRelOrder = (record: any) => {
      relOrderModelRef.value.view(record);
    };

    const detailDrawerRef = ref<any>(null as any);
    const viewDetail = (id: number) => {
      detailDrawerRef.value.view(id);
    };

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
    }>({
      timeRange: [],
    });

    const onExport = () => {
      Object.assign(exportParams, {
        timeRange: [],
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (!exportParams.timeRange.length) {
        message.warning('请选择时间范围在导出');
        return;
      }
      if (exportParams.timeRange.length) {
        params.startDate = exportParams.timeRange[0].format('YYYY-MM-DD');
        params.endDate = exportParams.timeRange[1].format('YYYY-MM-DD');
      }
      let res: any;
      try {
        exporting.value = true;
        res = await request.get(createApiUrl('/newlinkSass/excel/down/invoiceDetail'), { responseType: 'blob', params: { ...params } });
      } catch (e: Error & any) {
        exporting.value = false;
        return message.error(e.message);
      }
      exporting.value = false;
      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`发票记录${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_tax_list_page_view', { page_name: '发票管理_发票记录页_浏览' });
    });

    return {
      typeList: [
        { label: '全部', value: '' },
        { label: '电子发票', value: 1 },
        { label: '增值税专用发票', value: 2 },
        // { label: '增值税普通发票', value: 3 },
      ],
      statusList: [
        { label: '全部', value: '' },
        { label: '待开票', value: 0 },
        { label: '开票成功', value: 1 },
        { label: '开票失败', value: 2 },
        { label: '待冲红', value: 3 },
        { label: '冲红成功', value: 4 },
      ],
      tableColumns,
      filterParams,

      currentErrorMsg,
      errorModalVisible,
      showError,

      tableRef,
      loadTableData,
      onRefreshTable,

      relOrderModelRef,
      onViewRelOrder,

      detailDrawerRef,
      viewDetail,

      isDateDisable,
      onOpenChange,
      onCalendarChange,

      onExport,

      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,
    };
  },
});
