
import { defineComponent, computed, ref, reactive, toRaw, watch, onMounted, watchEffect, getCurrentInstance } from 'vue';
import { message } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';

// 1 企业共享支付 2 企业个人账户余额 3 三方支付（支付宝）4 三方支付（微信）5 优惠券
export default defineComponent({
  name: 'IssueHoupiaoListView',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    // 白名单客户”开发票“页面去掉开发票功能，替换成统一提示文案。
    const invoiceOption = reactive<{
      offlineInvoicing: boolean, // 是否可开票 true 线下开票 false 线上开票
      name: string | any,
      phone: string | any,
    }>({
      offlineInvoicing: true,
      name: '',
      phone: '',
    });
    const ifOfflineInvoicing = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/invoice/offline-invoicing'), {});
        invoiceOption.offlineInvoicing = true; // 线下开票，联系BD
        invoiceOption.name = res.data.name;
        invoiceOption.phone = res.data.phone;
      } catch (e: Error & any) {
        message.error(e.message);
        throw e;
      }
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_tax_discount_page_view', { page_name: '发票管理_折扣卡开发票_浏览' });
      ifOfflineInvoicing();
    });
    
    return {
      invoiceOption,
    };
  },
});
