
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { defineComponent, ref, reactive, UnwrapRef } from 'vue';
import { Modal } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'FillInvoice',
  props: {
    invoiceRecord: { type: Object, default: () => ({}) },
  },
  emits: ['close'],
  setup(props, ctx) {
    const loading = ref<boolean>(false);
    const visible = ref<boolean>(true);
    const showForm = ref<boolean>(true);
    const formRef = ref();
    const invoiceAmount = ref<any>(props.invoiceRecord.value.invoiceAmount);

    const handleCancel = () => {
      ctx.emit('close');
    };
    type InvoiceInfo = {
      batchId: number,
      type: number,
      name: string,
      duty: string,
      address: string,
      phone: string,
      bank: string,
      bankAct: string,
      desc: string,
      email: string,
    }
    const invoiceInfo:UnwrapRef<InvoiceInfo> = reactive({
      batchId: props.invoiceRecord.value.batchId,
      type: 1,
      name: '',
      duty: '',
      address: '',
      phone: '',
      bank: '',
      bankAct: '',
      desc: '',
      email: '',
    });

    const rules = {
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      duty: [{ required: true, message: '请输入企业税号', trigger: 'blur' }],
      email: [{ required: true, message: '请输入电子邮箱', trigger: 'blur' }],
    };

    const toConfirm = () => {
      formRef.value.validate()
        .then(() => {
          showForm.value = false;
        })
        .catch((error: ValidateErrorEntity<InvoiceInfo>) => {
          console.log(error);
        });
    };

    const confirmInvoice = () => {
      request.post(createApiUrl('/newlinkSass/prepaidCard/applyInvoice'), invoiceInfo)
        .then(res => {
          Modal.success({
            title: '提交成功',
            content: '开票申请已提交成功，开票完成后将发送到填写的邮箱中。',
            onOk: () => {
              ctx.emit('close', true);
            },
          });
        })
        .catch(error => {
          Modal.error({
            title: '提交失败',
            content: error.message,
          });
        });
    };

    return {
      visible,
      handleCancel,
      invoiceInfo,
      loading,
      toConfirm,
      formRef,
      rules,
      showForm,
      confirmInvoice,
      invoiceAmount,
    };
  },
});
