import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event)),
    class: "addressEditorModal",
    title: _ctx.title,
    style: {width: '800px'},
    "confirm-loading": _ctx.submiting,
    onOk: _ctx.onSubmit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "editorFormRef",
        model: _ctx.formData,
        rules: _ctx.rules,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol,
        onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "选择地区",
            name: "area"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_cascader, {
                value: _ctx.formData.area,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.area) = $event)),
                options: _ctx.areaData,
                placeholder: "请选择地区"
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "详细地址",
            name: "address"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formData.address,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.address) = $event)),
                maxlength: 60,
                placeholder: "请输入详细地址",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "收件人",
            name: "receiveName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formData.receiveName,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.receiveName) = $event)),
                maxlength: 25,
                placeholder: "长度不超过25个字符",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "联系电话",
            name: "receiveTel"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formData.receiveTel,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.receiveTel) = $event)),
                maxlength: 20,
                placeholder: "输入联系电话",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules", "label-col", "wrapper-col", "onSubmit"])
    ]),
    _: 1
  }, 8, ["visible", "title", "confirm-loading", "onOk"]))
}