
import { defineComponent, reactive, ref, computed } from 'vue';
import { Form, message } from 'ant-design-vue';
import { not, mobileReg } from '@/utils/illuminate';

import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { request } from '@/utils/request';

import areaTree from '@/assets/area-tree.json';

const mapArea = (data: any[]) => data.map(item => {
  item.value = item.id;
  item.label = item.name;
  if (item.children) item.children = mapArea(item.children);
  return item;
});

export default defineComponent({
  name: 'AddressEditorModal',
  emits: ['complete'],
  setup(props, ctx) {
    const visible = ref(false);
    const isEdit = ref(false);
    const submiting = ref(false);
    const title = () => `${isEdit.value ? '修改' : '添加'}收货地址`;

    const defaultData = {
      area: [],
      address: '',
      receiveName: '',
      receiveTel: '',
    };

    const record = ref<any>({});
    const formData = reactive<{
      area: number[];
      address: '';
      receiveName: '';
      receiveTel: '';
    }>({
      area: [],
      address: '',
      receiveName: '',
      receiveTel: '',
    });

    const rules = {
      area: [
        { required: true, type: 'array', message: '请选择地区', trigger: 'change' },
      ],
      address: [
        { required: true, message: '请输入详细地址', trigger: 'change' },
      ],
      receiveName: [
        { required: true, message: '请输入姓名', trigger: 'change' },
        {
          validator: (rule: any, value: string, ...args: any[]) => (value.length > 25
            ? Promise.reject('姓名不可大于25个字符') : Promise.resolve()),
          trigger: 'change',
        },
      ],
      receiveTel: [
        { required: true, message: '请输入联系电话', trigger: 'change' },
        { pattern: mobileReg, message: '请输入正确的联系电话', trigger: 'change' },
      ],
    };


    const editorFormRef = ref<typeof Form>(null as any);

    const resetFields = () => {
      editorFormRef.value?.resetFields();
      Object.assign(formData, defaultData);
    };

    const create = () => {
      resetFields();
      Object.assign(formData, defaultData);
      visible.value = true;
      isEdit.value = false;
    };

    const edit = (item: any) => {
      isEdit.value = true;
      visible.value = true;
      record.value = item;
      resetFields();
      Object.assign(formData, not(item, ['area']));
      const area = [];
      if (item.province) area.push(+item.province);
      if (item.city) area.push(+item.city);
      if (item.area) area.push(+item.area);
      formData.area = area;
    };

    const onSubmit = () => {
      //
      editorFormRef.value.validate()
        .then(() => {
          const data: any = { ...formData };
          data.area = formData.area[2] || '';
          data.city = formData.area[1] || '';
          data.province = formData.area[0] || '';
          if (isEdit.value) {
            data.id = record.value.id;
          }
          submiting.value = true;
          return request.put(createApiUrl('/newlinkSass/invoice/create-or-update-invoice-address'), data);
        })
        .then(() => {
          resetFields();
          message.success(isEdit.value ? '保存完成' : '创建完成');
          visible.value = false;
          ctx.emit('complete');
        })
        .catch((e: any) => {
          message.error(isValidateError(e) ? firstError(e) : e.message);
        })
        .finally(() => {
          submiting.value = false;
        });
    };

    return {
      areaData: mapArea(areaTree),
      labelCol: { span: 6 },
      wrapperCol: { span: 14, offset: 1 },

      visible,
      title,
      submiting,

      create,
      edit,

      formData,
      rules,
      editorFormRef,
      onSubmit,
    };
  },
});
