
import { defineComponent, onMounted, ref } from 'vue';
import { message } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { invoiceTypeMap } from '@/views/fapiao/parts/utils';

export default defineComponent({
  name: 'HouPiaoDetail',
  setup(props, ctx) {
    const detailData = ref({
      accountBank: '',
      accountNo: '',
      applyDate: '',
      applyInvoiceTotalFee: 0,
      applyUser: '',
      applyPhone: '',
      email: '',
      emailTime: '',
      expressName: '',
      expressNo: '',
      goodsPrice: '',
      goodsQuantity: '',
      goodsSpecification: '',
      goodsTaxRate: '',
      goodsTotalPrice: '',
      goodsUnit: '',
      invoiceContent: '',
      invoiceNo: '',
      invoiceRedType: 0,
      invoiceRedTypeStr: '',
      invoiceTitle: '',
      invoiceTotalPriceTax: '',
      invoiceTotalTax: '',
      invoiceType: 1,
      invoiceTypeStr: '',
      mailTimes: 0,
      mailingStatus: 0,
      mailingStatusStr: '',
      mailingTime: '',
      oilInvoiceNo: '',
      oilInvoiceStatus: 0,
      oilInvoiceStatusStr: '',
      registAddress: '',
      registTel: '',
    });

    // = ---------------------------- = 数据加载 = ---------------------------- =
    let invoiceRecordId = 0;
    const loadData = async () => {
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/invoice/find-invoice-detail'), { invoiceRecordId });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      detailData.value = res.data;
    };

    // = ---------------------------- = 抽屉显示隐藏 = ---------------------------- =

    const visible = ref(false);
    const view = (no: number) => {
      invoiceRecordId = no;
      visible.value = true;
      loadData();
    };

    return {
      detailData,
      tableData: [],
      tableColumns: [
        { title: '操作类型', dataIndex: 'mobile' },
        { title: '时间', dataIndex: 'num' },
        { title: '操作人', dataIndex: 'department' },
        { title: '操作内容', dataIndex: 'canPay' },
      ],

      pagination: { total: 100 },
      invoiceTypeMap,

      visible,
      view,
    };
  },
});
