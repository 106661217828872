
import { defineComponent, computed, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import moment, { Moment } from 'moment';

import { noEmptyProp, not } from '@/utils/illuminate';

import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';
import fillInvoice from './parts/fillInvoice.vue';
import { invoiceStatusList } from './parts/utils';

const invoiceStatusMap: Record<string, string> = {
  0: '待开票',
  1: '开票成功',
  2: '开票失败',
  3: '待冲红',
  4: '冲红成功',
  9: '申请失败',
};

export default defineComponent({
  name: 'FapiaoListView',
  components: {
    fillInvoice,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const filterParams = reactive<{
      applyDate: Moment[];
      batchId: string;
      enterpriseId: number | string;
    }>({
      applyDate: [],
      batchId: '',
      enterpriseId: '',
    });

    const tableColumns: TableColumn[] = [
      { title: '批次ID', dataIndex: 'batchId' },
      { title: '企业名称', dataIndex: 'enterpriseName' },
      { title: '面额', dataIndex: 'nominalAmount' },
      { title: '数量', dataIndex: 'num' },
      { title: '订单金额', dataIndex: 'orderAmount' },
      { title: '可开票金额', dataIndex: 'invoiceAmount' },
      { title: '支付时间', dataIndex: 'payTime' },
      { title: '开票时间', dataIndex: 'invoiceDate' },
      { title: '开票状态', dataIndex: 'invoiceStatus', customRender: ({ text }): string => invoiceStatusList[text as keyof typeof invoiceStatusList] },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const tableRef = ref<any>(null);
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      const params: Record<string, any> = {
        ...parameter, ...not(noEmptyProp(filterParams), ['applyDate']),
      };

      if (filterParams.applyDate.length) {
        params.applyDateBegin = filterParams.applyDate[0].format('YYYY-MM-DD 00:00:00');
        params.applyDateEnd = filterParams.applyDate[1].format('YYYY-MM-DD 23:59:59');
      }

      request.post(createApiUrl('/newlinkSass/prepaidCard/applyInvoiceList'), { ...params })
        .then((res: any) => {
          resolve({
            ...res.data,
            list: res.data.list.map((item: any) => ({
              ...item, id: `${item.invoiceNo}${item.id}`,
            })),
          });
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    // ********** 开发票 **********
    const showConfirmModal = ref<boolean>(false);
    const closeConfirmModal = (isRefresh = false) => {
      showConfirmModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };

    const invoiceRecord = reactive<any>({});
    const viewDetail = (record: any):void => {
      invoiceRecord.value = record;
      showConfirmModal.value = true;
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_tax_credit_page_view', { page_name: '发票管理_预付卡开发票_浏览' });
    });

    return {
      tableColumns,
      filterParams,
      tableRef,
      loadTableData,
      onRefreshTable,
      viewDetail,
      showConfirmModal,
      closeConfirmModal,
      invoiceRecord,
    };
  },
});
