
import { defineComponent, onMounted, computed, reactive, ref, watch, nextTick } from 'vue';
import { Form, message } from 'ant-design-vue';
import { mobileReg, deepReduce, not, emailReg } from '@/utils/illuminate';

import { useStore } from 'vuex';
import { getHoupiaoStore, HOUPIAO_FORM_KEY } from '@/views/fapiao/parts/utils';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { useCurrentEnterpriseHook } from '@/store';
import { EnterpriseInputItem } from '@/views/parts/subsidiary';
import { request } from '@/utils/request';
import areaTree from '@/assets/area-tree.json';
import router from '@/router';

import AddressEditorModal from './parts/AddressEditorModal.vue';

const emailDomainList = ['.com', '.cn', '.net'];
const emailDomainRegList = emailDomainList.map(i => new RegExp(`\\${i}$`, 'i'));
const emailDomainError = `请输入 ${emailDomainList.join(',')} 结尾的邮箱`;

export default defineComponent({
  name: 'IssueHoupiaoForm',
  components: {
    AddressEditorModal,
  },
  setup(props, ctx) {
    const store = useStore();
    const storage = getHoupiaoStore();
    const orderParams: {
      isAll: boolean,
      startTime: number,
      endTime: number,
      energyType: number,
      enterprise: EnterpriseInputItem,
      usercode: string | undefined,
      vehicleId: string | undefined,
    } = storage.get(HOUPIAO_FORM_KEY);
    useCurrentEnterpriseHook(orderParams.enterprise.enterpriseId, orderParams.enterprise as any);

    store.commit('User/setCurrentEnterpriseId', orderParams.enterprise.enterpriseId);

    const formData = reactive({
      invoiceType: '1',
      addressId: '',
      accountBank: '',
      accountNo: '',
      registAddress: '',
      registTel: '',
      remark: '',
      invoiceReceiveEmail: '',
      // buyerEmail: '',
      invoiceReceiveTel: '',
      receiveName: '',
    });

    const rules = computed(() => {
      const isPro = formData.invoiceType === '2';
      // const isPro = false;

      return {
        accountBank: [
          { required: false, message: '请输入开户银行', type: 'string', trigger: 'blur' },
          {
            validator: (rule: any, value: any) => (value.length > 100 ? Promise.reject('开户行不得超过 100 个字符!') : Promise.resolve()),
            trigger: 'change',
          },
        ],
        accountNo: [
          { required: false, message: '请输入银行账号', type: 'string', trigger: 'change' },
          { pattern: /^\d+$/, message: '账号格式错误', type: 'string', trigger: 'change' },
          { max: 32, message: '银行账号不得超过 32 位', type: 'string', trigger: 'change' },
        ],
        registAddress: [
          { required: false, message: '请输入企业注册地址', type: 'string', trigger: 'blur' },
          {
            validator: (rule: any, value: any) => (value.length > 100 ? Promise.reject('企业注册地址不得超过 100 个字符!') : Promise.resolve()),
            trigger: 'change',
          },
        ],
        registTel: [
          { required: false, message: '请输入企业固定电话', type: 'string', trigger: 'blur' },
          {
            validator: (rule: any, value: any) => {
              if (!value) return Promise.resolve();

              return (mobileReg.test(value) || /^\d{3,4}-\d{7,8}$/.test(value))
                ? Promise.resolve()
                : Promise.reject('企业固定电话格式错误!');
            },
            trigger: 'change',
          },
        ],

        remark: [
          { validator: (rule: any, value: any) => (value.length > 50 ? Promise.reject('备注长度不得超过 50 个字符!') : Promise.resolve()) },
        ],
        addressId: [
          { required: isPro, type: 'number', message: '请选择收件地址', trigger: 'change' },
        ],
        invoiceReceiveEmail: [
          { required: !isPro, message: '请输入收件邮箱', trigger: 'blur' },
          {
            validator: (rule: any, value: any) => {
              if (!value && isPro) return Promise.resolve();

              // if (!value) return Promise.reject('请输入收件邮箱');

              if (!emailReg.test(value)) return Promise.reject('收件邮箱格式错误');

              return Promise.resolve();
            },
            trigger: 'change',
          },
          {
            validator: (rule: any, value: any) => {
              const valied = emailDomainRegList.some(item => item.test(value));
              return valied ? Promise.resolve() : Promise.reject(emailDomainError);
            },
            trigger: 'change',
          },
        ],
      };
    });

    const loadTitleDetail = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/invoice/find_last_invoice_info'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      // 发票抬头是列表页面跳转携带

      const resData = res.data;
      formData.invoiceType = resData.invoiceType.toString() || '1';
      formData.accountBank = resData.accountBank || '';
      formData.accountNo = resData.accountNo || '';
      formData.registAddress = resData.registAddress || '';
      formData.registTel = resData.registTel || '';
      formData.invoiceReceiveEmail = resData.invoiceReceiveEmail || '';
      // formData.buyerEmail = resData.invoiceReceiveEmail || '';
      formData.remark = resData.remark || '';
    };


    /* = ---------------------------- = 统计 = ---------------------------- = */
    const statistics = ref({ orderNum: 0, totalAmount: 0 });

    const invoiceOrderStatistics = async () => {
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/invoice/find-center-invoice-order-statisticsV2'), { ...not(orderParams, ['enterprise']) });
      } catch (e: Error & any) {
        message.error(e.message);
        throw e;
      }

      statistics.value = res.data || { orderNum: 0, totalAmount: 0 };
    };

    // = ---------------------------- = 编辑收货地址 = ---------------------------- =
    const addressLoaded = false;
    const addressList = ref<any[]>([]);
    const addressPagination = reactive({
      total: 1,
      pageNo: 1,
      totalPage: 1,
    });

    const loadAddressList = async (pageNo = 1) => {
      pageNo = pageNo > addressPagination.totalPage ? (addressPagination.totalPage || 1) : (pageNo || 1);
      formData.addressId = '';

      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/invoice/find-invoice-address-list'), {
          params: { pageNo, pageSize: 5 },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      const resData = res.data;

      addressList.value = resData.list;
      addressPagination.total = resData.total;
      addressPagination.pageNo = pageNo;
      addressPagination.totalPage = Math.ceil(resData.total / 5);

      // 如果超限 加载最后一页
      if (resData.total && !resData.list.length) {
        loadAddressList(addressPagination.totalPage);
      }
    };

    watch(() => formData.invoiceType, val => {
      if (val === '2' && !addressLoaded) loadAddressList(1);
    });

    const addressEditorRef = ref<any>(null);
    const onCreateAddress = () => {
      addressEditorRef.value.create();
    };
    const onEditAddress = (item: any) => {
      addressEditorRef.value.edit(item);
    };
    const onDelAddress = async (id: number) => {
      //
      try {
        await request.put(createApiUrl('/newlinkSass/invoice/del-invoice-address'), { id });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      loadAddressList(1);
    };

    const renderAddress = (item: any) => {
      const ids = [+item.province, +item.city, +item.area];
      const areaNames = deepReduce<string[]>(areaTree, 'children', (prev, curr, index, list) => {
        if (ids.indexOf(curr.id) === -1) return prev;

        prev.push(curr.name as string);
        return prev;
      }, []);

      return `${areaNames.join('')} ${item.address}`;
    };

    const submiting = ref(false);
    const editorFormRef = ref<typeof Form>(null as any);

    watch(() => formData.invoiceType, () => {
      nextTick(() => {
        editorFormRef.value.clearValidate();
      });
    });

    const getReciveInfo = () => {
      if (formData.invoiceType === '1') {
        return {
          expAddress: '',
          receiveName: '',
          invoiceReceiveTel: '',
        };
      }

      const item = addressList.value.filter(item => (item.id === formData.addressId)).pop()!;
      return {
        expAddress: renderAddress(item),
        receiveName: item.receiveName,
        invoiceReceiveTel: item.receiveTel,
      };
    };

    const onSubmit = async () => {
      try {
        await editorFormRef.value.validate();

        if (submiting.value) return;
        submiting.value = true;
        await request.put(createApiUrl('/newlinkSass/invoice/bill-invoice-order'), {
          ...not(formData, ['addressId']),
          // ...getReciveInfo(),
          ...not(orderParams, ['enterprise']),
          invoiceSource: 1,
          billType: 2,
        });
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }

      message.success('发票申请提交完成');
      router.push({ name: 'fapiao.houpiao.list' });
    };

    onMounted(() => {
      loadTitleDetail();
      invoiceOrderStatistics();
    });

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 16, offset: 1 },
      orderParams,
      statistics,

      addressList,
      addressPagination,
      loadAddressList,

      addressEditorRef,
      onCreateAddress,
      onEditAddress,
      onDelAddress,
      renderAddress,

      formData,
      rules,
      submiting,
      editorFormRef,
      onSubmit,
    };
  },
});
