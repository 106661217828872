
import { defineComponent, reactive, ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import { formatWithIntl } from '@/utils/illuminate';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';

export default defineComponent({
  name: 'AddressEditorModal',
  emits: ['complete'],
  setup(props, ctx) {
    const visible = ref(false);
    const submiting = ref(false);
    const record = ref({ invoiceRecordId: 0, invoiceNo: '' });
    const list = ref<{}[]>([]);

    const tableColumns: TableColumn[] = [
      { title: '订单编号', dataIndex: 'orderNo' },
      { title: '用户姓名', dataIndex: 'createUser', customRender: ({ text }) => text || '---' },
      { title: '用户手机号', dataIndex: 'phone' },
      { title: '订单金额', dataIndex: 'totalRealAmount', customRender: ({ text }) => `${formatWithIntl(text)} 元` },
    ];

    const loadList = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/invoice/find-order-by-invoice-no'), {
          params: { invoiceId: record.value.invoiceRecordId, invoiceNo: record.value.invoiceNo },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      list.value = res.data;
    };

    const view = (item: any) => {
      visible.value = true;
      record.value = item;
      loadList();
    };

    const onCancel = () => {
      list.value = [];
    };

    return {
      visible,
      submiting,

      view,
      list,
      tableColumns,
      onCancel,
    };
  },
});
