
import { defineComponent, computed, ref, reactive, toRaw, watch, onMounted, watchEffect, getCurrentInstance } from 'vue';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Moment } from 'moment';
import { settleTree, formatWithIntl, noEmptyProp, not } from '@/utils/illuminate';
import { createApiUrl } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { getHoupiaoStore, HOUPIAO_FORM_KEY } from '@/views/fapiao/parts/utils';
import { EnterpriseInputItem } from '@/views/parts/subsidiary';

// 1 企业共享支付 2 企业个人账户余额 3 三方支付（支付宝）4 三方支付（微信）5 优惠券
export default defineComponent({
  name: 'IssueHoupiaoListView',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const router = useRouter();
    const store = useStore();
    // 白名单客户”开发票“页面去掉开发票功能，替换成统一提示文案。
    const invoiceOption = reactive<{
      offlineInvoicing: boolean, // 是否可开票 true 线下开票 false 线上开票
      name: string | any,
      phone: string | any,
    }>({
      offlineInvoicing: false,
      name: '',
      phone: '',
    });
    const ifOfflineInvoicing = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/invoice/offline-invoicing'), {});
        invoiceOption.offlineInvoicing = res.data.offlineInvoicing;
        invoiceOption.name = res.data.name;
        invoiceOption.phone = res.data.phone;
      } catch (e: Error & any) {
        message.error(e.message);
        throw e;
      }
    };
    ifOfflineInvoicing();
    const isOverActionTip = ref<boolean>(false);
    const filterParams = reactive<{
      usercode: string | undefined,
      vehicleId: string | undefined,
      isAll: boolean;
      energyType: number | string,
      statementBillNo: undefined | string,
      dateRange: Moment[],
      enterpriseId: number | string,
      departmentId: number | undefined,
      enterprise: EnterpriseInputItem,
    }>({
      usercode: undefined,
      vehicleId: undefined,
      isAll: false,
      energyType: 1,
      statementBillNo: undefined,
      dateRange: [],
      enterpriseId: store.state.User.enterprise.enterpriseId,
      departmentId: undefined,
      enterprise: store.state.User.enterprise,
    });

    /* = ----------------------------员工 = */
    const userList = ref<Array<Record<string, any>>>([]);
    // 获取员工列表
    const handleUserSearch = async (val: string) => {
      if (val) {
        const params = {
          nameOrPhone: val,
          enterpriseId: filterParams.enterpriseId, 
          status: 'ALL',
        };
        request.get<Record<string, string>[]>(createApiUrl('/newlinkSass/enterprise/find-user-list-by-name-or-phone'), { params }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.message);
          userList.value = data;
        });
      }
    }; 
    
    /* = ----------------------------车辆 --------------------------------= */
    const vehicleList = ref<Array<Record<string, string>>>([]);
    // 模糊搜索
    const handleVehicleSearch = (value: any) => {
      if (value) {
        const params = { licensePlateOrVin: value, enterpriseId: filterParams.enterpriseId };
        request.get(createApiUrl('/newlinkSass/vehicle/fuzzySearchVehicle'), { params }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.message);
          vehicleList.value = data;
        });
      }
    };

    /* = ---------------------------- = 统计 = ---------------------------- = */
    const statistics = ref({ orderNum: 0, totalAmount: 0 });

    const actionTipVisible = computed({
      get(this: any): boolean {
        return !statistics.value.orderNum && isOverActionTip.value;
      },
      set(this: any, val: boolean) {
        isOverActionTip.value = val;
      },
    });

    const invoiceOrderStatistics = async () => {
      const params: any = {
        ...noEmptyProp(not(filterParams, ['dateRange', 'enterprise', 'statementBillNo'])),
        enterpriseId: filterParams.enterprise.enterpriseId,
        departmentId: filterParams.departmentId,
      };

      if (filterParams.dateRange.length) {
        params.startTime = filterParams.dateRange[0].valueOf();
        params.endTime = filterParams.dateRange[1].valueOf();
      }

      if (filterParams.statementBillNo) {
        params.statementBillNoList = [filterParams.statementBillNo];
      }

      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/invoice/find-center-invoice-order-statisticsV2'), params);
      } catch (e: Error & any) {
        message.error(e.message);
        throw e;
      }

      statistics.value = res.data || { orderNum: 0, totalAmount: 0 };
    };

    const loadTableData = async (parameter: any) => {
      if (!filterParams.dateRange.length && !filterParams.isAll) return { list: [] };

      const params: any = {
        ...noEmptyProp(not(filterParams, ['dateRange', 'enterprise', 'statementBillNo'])),
        enterpriseId: filterParams.enterprise.enterpriseId,
        departmentId: filterParams.departmentId,
      };

      if (filterParams.dateRange.length) {
        params.startTime = filterParams.dateRange[0].valueOf();
        params.endTime = filterParams.dateRange[1].valueOf();
      }

      if (filterParams.statementBillNo) {
        params.statementBillNoList = [filterParams.statementBillNo];
      }

      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/invoice/find-center-invoice-order-listV2'), { ...parameter, ...params });
      } catch (e: Error & any) {
        message.error(e.message);
        throw e;
      }
      return res.data || {};
    };

    const tableRef = ref<any>(null as any);

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const onClearTable = () => {
      tableRef.value && tableRef.value.clear(true);
    };

    // 点击全选按钮
    watch(filterParams, val => {
      if (val.isAll && filterParams.dateRange.length) {
        filterParams.dateRange = [];
        return;
      }

      if ((val.isAll && !val.dateRange.length) || (!val.isAll && val.dateRange.length)) {
        onRefreshTable();
        invoiceOrderStatistics();
      } else {
        onClearTable();
        statistics.value = { orderNum: 0, totalAmount: 0 };
      }
    });

    const tableColumns: TableColumn[] = [
      { title: '员工姓名', dataIndex: 'userName', width: '150px', align: 'center' },
      { title: '手机号码', dataIndex: 'phone', width: '150px', align: 'center' },
      { title: '对账单编码', dataIndex: 'statementBillNo', width: '180px', customRender: ({ text }) => text || '--', align: 'center' },
      { title: '车辆', slots: { customRender: 'licensePlate' }, width: '150px', align: 'center' },
      { title: '金额', dataIndex: 'payAmount', customRender: ({ text }) => formatWithIntl(text), align: 'center', width: '120px' },
      { title: '油号', dataIndex: 'oilName', width: '120px', align: 'center' },
      { title: '油站', dataIndex: 'gasName', width: '150px', align: 'center' },
      { title: '能源类型', dataIndex: 'energyTypeStr', width: '120px', align: 'center' },
      { title: '支付方式', dataIndex: 'payTypeStr', width: '120px', align: 'center' },
      { title: '支付时间', dataIndex: 'payTime', width: '180px', align: 'center' },
      { title: '下单时间', dataIndex: 'createOrderDate', width: '180px', align: 'center' },
    ];

    /* = ---------------------------- = 能源类型选单 = ---------------------------- = */
    const energyTypeList = ref<{ code: string; name: string; }[]>([]);
    const loadEnergyType = async () => {
      let res;
      try {
        res = await request.get(createApiUrl('/newlinkSass/invoice/get-order-energy-type-list'));
      } catch (e: Error & any) {
        message.error(e.message);
        throw e;
      }

      energyTypeList.value = [{ code: '', name: '全部' }].concat(res.data);
      filterParams.energyType = '';
    };

    const onGotoForm = () => {
      if (!statistics.value.orderNum) return message.error('请筛选开票订单!');
      const storage = getHoupiaoStore();

      const params: any = { ...not(filterParams, ['dateRange', 'statementBillNo']) };

      if (filterParams.dateRange.length) {
        params.startTime = filterParams.dateRange[0].valueOf();
        params.endTime = filterParams.dateRange[1].valueOf();
      }

      if (filterParams.statementBillNo) {
        params.statementBillNoList = [filterParams.statementBillNo];
      }
      
      storage.set(HOUPIAO_FORM_KEY, params);
      router.push({ name: 'fapiao.issue_houpiao.form' });
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_tax_gas_page_view', { page_name: '发票管理_加油单开发票_浏览' });
      !invoiceOption.offlineInvoicing && loadEnergyType();
    });
    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async (val:any) => {
      filterParams.departmentId = undefined; // 清空部门
      filterParams.usercode = undefined;
      filterParams.vehicleId = undefined;
      vehicleList.value = [];
      userList.value = [];
      // 公司查询全部 部门为空
      if (val === '') {
        departmentList.value = [];
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'), {
          params: {
            enterpriseId: val,
          },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
    };
    watchEffect(() => {
      loadEnterpriseDepartment(filterParams.enterpriseId);
    });
    return {
      invoiceOption,
      statistics,
      filterParams,
      departmentList,
      isOverActionTip,
      actionTipVisible,
      tableColumns,

      tableRef,
      loadTableData,
      onRefreshTable,
      onGotoForm,

      energyTypeList,
      vehicleList,
      handleVehicleSearch,
      userList,
      handleUserSearch,
    };
  },
});
