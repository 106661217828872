
import { defineComponent, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';
import AddressEditorModal from './parts/AddressEditorModal.vue';

export default defineComponent({
  name: 'AddressListView',
  components: {
    AddressEditorModal,
  },
  setup(props, ctx) {
    const filterParams = reactive({});

    const tableColumns: TableColumn[] = [
      { title: '收件人', dataIndex: 'receiveName', width: '25%' },
      { title: '联系电话', dataIndex: 'receiveTel', width: '25%' },
      { title: '地址', dataIndex: 'address', width: '25%', customRender: ({ text }) => <span class="address">{text}</span> },
      { title: '操作', dataIndex: 'action', width: '25%', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/invoice/find-invoice-address-list'), { params: parameter })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null as any);

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const editorRef = ref<any>(null);
    const onCreateItem = () => {
      editorRef.value.create();
    };

    const onEditItem = (record: any) => {
      editorRef.value.edit(record);
    };

    const onDelItem = async (record: any) => {
      //
      try {
        await request.put(createApiUrl('/newlinkSass/invoice/del-invoice-address'), { id: record.id });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      onRefreshTable();
    };

    return {
      filterParams,
      tableColumns,

      tableRef,
      loadTableData,
      onRefreshTable,

      editorRef,
      onCreateItem,
      onEditItem,
      onDelItem,
    };
  },
});
